body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

@primary-color: #24C4CC;@info-color: #24C4CC;@processing-color: #24C4CC;@highlight-color: #DEFA3D;@font-family: "GT Walsheim", "Noto Sans HK", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang TC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";@grid-columns: 24;@grid-gutter-width: 8;@error-color: #ff4d4f;@btn-primary-color: #201A3D;@heading-color: #201A3D;@menu-item-group-title-color: #201A3D;@radio-button-active-color: #201A3D;@select-background: #24C4CC;@select-dropdown-bg: #ECECEB;@select-item-active-bg: #24C4CC;@select-item-selected-bg: #24C4CC;@text-color: #201A3D;@layout-header-background: #201A3D;@layout-header-padding: 0 16px;@layout-body-background: #FFFFFF;@form-item-margin-bottom: 8px;@tabs-card-head-background: #FFFFFF;@tabs-hover-color: #24C4CC;@tabs-card-active-color: #FFFFFF;@tabs-card-gutter: 0;@table-header-bg: #24C4CC;@table-header-color: #FFFFFF;@border-color-base: #24C4CC;